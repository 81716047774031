import * as React from "react";
import Layout from "../../components/layout";
import SecondarySchoolContent from "../../components/education/secondarySchoolContent";
import heroImage from "../../assets/education/secondary-level-hero-image.jpg";
import BannerImage from "../../components/master/BannerImage";

const SecondaryYearPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="Secondary level" />
      <SecondarySchoolContent />
    </Layout>
  );
};

export default SecondaryYearPage;
