import React from "react";
import { educational } from "../const";
import ContentWrapper from "../master/ContentWrapper";
import PageTitle from "../master/PageTittle";
import SideBar from "../master/SideBar";

const SecondarySchoolContent = () => (
  <div className="container">
    <ContentWrapper sidebar={true} narrow={true}>
      <div className={"content"}>
        <PageTitle title="Secondary School" />

        <p>
          At Saint Maroun's College, Secondary years are an exciting and
          memorable time for our students. With small numbers in each class,
          students receive individualised time with teachers. They are
          encouraged and challenged on a daily basis.
        </p>

        <p>
          Secondary students at Saint Maroun's College come from diverse
          cultural and religious backgrounds and celebrate their differences
          together. It is a welcoming place for students of all abilities and
          their teachers are very supportive. It is a friendly environment where
          all students know each other and respect one another.
        </p>

        <p>
          The College implements quality learning practices that enhance student
          achievement and support students to take responsibility for their own
          learning.
        </p>

        <p>
          The grounds are beautiful and peaceful with plenty of green areas,
          basketball courts, volleyball court, garden patch and a gym.
        </p>

        <p>
          The College participates in representative sport and students are
          encouraged to try out for team sports including basketball, touch
          football, volleyball, soccer and cricket teams.
        </p>

        <p>
          Additional learning opportunities are exist within our
          extra-curricular activities which include coding club, homework and
          math club, chess club, social justice group, SRC, environmental group,
          musicals, mock trial etc.
        </p>

        <p>
          We welcome you to come and visit our grounds to see our facilities and
          meet our staff and students.
        </p>
      </div>
      <SideBar items={educational} title="Educational Excellence" />
    </ContentWrapper>
  </div>
);

export default SecondarySchoolContent;
